import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Portal } from "react-portal";

import iconClose from "assets/img/icon/close.png";

const PopupNotice = observer(({ onOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <Portal>
      <div className="custom-modal">
        <div className="custom-mask" />
        <div className="custom-modal-body !w-[80%] !h-[450px] !bg-white !bg-no-repeat !bg-center !bg-cover relative">
          <div
            className="w-[36px] h-[36px] absolute bottom-[-50px] left-[47%]"
            onClick={onClose}
          >
            <img src={iconClose} alt="" className="w-[36px] h-[36px]" />
          </div>
          <div className="custom-modal-header h-[170px] text-[16px] bg-[#c94848] text-[#e7e7e7] text-center pt-[40px] flex flex-col">
            <span className="text-[26px] font-[700] text-[#ffffff]">
              Thông báo mới nhất
            </span>
            <span className="text-[22px] tracking-[.34em] text-[#ffffff]">
              NOTICE
            </span>
          </div>
          <div className="custom-modal-content w-[85%] h-[280px] mx-[auto] flex flex-col overflow-y-scroll">
            <dd className="text-[13px] text-black">
              <p>FIAT-USD giao dịch thời gian và các loại giao dịch đơn hàng</p>
              <p>
                <br />
              </p>
              <p>
                Dữ liệu hàng ngày theo giờ quốc tế FIAT-USD (UTC) để tính, thời
                gian nạp tiền FIAT-USD là 24/7
              </p>
              <p>
                <br />
              </p>
              <p>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Quy
                trình giao dịch
              </p>
              <p>
                <br />
              </p>
              <p>
                1: Ấn vào &quot;Tôi muốn bán&quot; để bán USDT có trong tài
                khoản PTC của bạn cho thương gia. Vui lòng chọn giá tốt nhất để
                thực hiện bán ra. Sau khi bạn hoàn thành bán ra số USDT này cho
                thương gia, thương gia sẽ trả lại số tiền tương ứng với Việt Nam
                Đồng cho vào tài khoản của bạn.
              </p>
              <p>
                <br />
              </p>
              <p>
                2: Khi bạn hoàn tất giao dịch USDT, số tiền Việt Nam đồng tương
                ứng sẽ có trong số dư tài khoản của bạn, sau đó bạn ấn đăng ký
                rút tiền, khoảng 10-60 phút sau tiền sẽ đến tài khoản của bạn.
              </p>
              <p>
                <br />
              </p>
              <p>
                Chú ý: Số dư USDT của một đơn giao dịch lẻ càng lớn, thì đơn giá
                bạn nhận được càng cao. Bạn có thể tìm thương gia phù hợp với
                giao dịch theo số dư USDT trong tài khoản của bạn!
              </p>
              <p>
                <br />
              </p>
              <p>Thời gian phục vụ khách hàng của PTC 10: 00 ~ 22: 00</p>
              <p>
                <br />
              </p>
              <p>
                Thời gian hệ thống hoạt động từ 10h đến 22h trong ngày, đây là
                thời gian hệ thống xử lý trực tiếp. ngoài thời gian này hệ thống
                được xử lý tự động theo chế độ xếp hàng tự động.
              </p>
            </dd>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default PopupNotice;
