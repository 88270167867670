import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useMutation } from "@tanstack/react-query";
import { ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Portal } from "react-portal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

const PopupPassword = observer(({ onOpen, onClose }) => {
  const {
    authStore: { clear },
  } = useStores();
  const schema = yup
    .object({
      new_password: yup.string().required(i18next.t("verify_require_password")),
      confirmed: yup
        .string()
        .required(i18next.t("verify_require_confirm_password"))
        .oneOf(
          [yup.ref("new_password")],
          i18next.t("confirm_password_not_match")
        ),
    })
    .required();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.changePassword({ params: variables }),
    {
      onSuccess: (res) => {
        clear();
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = () => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    console.log(formValue);
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  return (
    <Portal>
      <div className="custom-modal">
        <div className="custom-mask" onClick={onClose} />
        <div className="custom-modal-body !w-full text-[16px] !bg-white !rounded-b-[0] !top-[unset] !right-[0] !bottom-[0] !left-[0] !translate-y-[0] !translate-x-[0]">
          <div className="custom-modal-header !h-[48px] !leading-[48px] text-black text-center relative">
            {i18next.t("change_password")}
            <FontAwesomeIcon
              icon={faClose}
              color="#c8c9cc"
              className="w-[22px] h-[48px] absolute right-[10px]"
              onClick={onClose}
            />
          </div>
          <div className="custom-modal-content text-black mx-[auto] flex flex-col">
            <form onSubmit={handleSubmit(onSave)}>
              <div className="py-[12px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
                <span className="w-[30%]">{i18next.t("old_password")}</span>
                <input
                  type="password"
                  placeholder={i18next.t("old_password")}
                  className="custom-input w-[70%] text-[14px] text-[#bbb] !bg-[transparent]"
                />
              </div>
              <div className="py-[12px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
                <span className="w-[30%]">{i18next.t("new_password")}</span>
                <input
                  type="password"
                  placeholder={i18next.t("new_password")}
                  className="custom-input w-[70%] text-[14px] text-[#bbb] !bg-[transparent]"
                  {...register("new_password")}
                />
              </div>
              <div className="py-[12px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
                <span className="w-[30%]">
                  {i18next.t("confirmed_password")}
                </span>
                <input
                  type="password"
                  placeholder={i18next.t("verify_require_confirm_password")}
                  className="custom-input w-[70%] text-[14px] text-[#bbb] !bg-[transparent]"
                  {...register("confirmed")}
                />
              </div>
              <div className="my-[15px] flex justify-center">
                <button
                  type="submit"
                  className="!w-[85%] !text-[18px] !text-white !text-center !bg-[#c94848] !rounded-[20px] !p-[10px]"
                >
                  {i18next.t("save")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default PopupPassword;
