import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next, { use } from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import coin_address from "assets/img/coin_address.png";
import { handleCopyLink } from "_common/utils/helper";
import QRCode from "react-qr-code";
import UploadFile from "_common/component/UploadFile";
import { useNotify } from "_common/component/NotifyProvider";

const schema = yup
  .object({
    amount: yup.string().required(i18next.t("verify_require")),
  })
  .required();

export default function Deposit() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { pushNotify } = useNotify();

  const symbol = params.get("symbol");
  const network = params.get("network");

  const [method, setMethod] = useState("vnd");
  const [address, setAddress] = useState();
  const [back_images, setBackImage] = useState();

  const {
    authStore: { user, general, user_wallet, game_wallet, coins },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  useEffect(() => {
    if (!mainCoin) return;
    if (symbol === "usdt") {
      if (network === "trc20") {
        setAddress(mainCoin?.trc20_address);
      } else if (network === "erc20") {
        setAddress(mainCoin?.erc20_address);
      } else {
        setAddress(mainCoin?.primary_address);
      }
    } else {
      setAddress(mainCoin?.primary_address);
    }
  }, [mainCoin, symbol, network]);

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          symbol,
          receive_address: address,
          network,
          payment_image: back_images,
          type: "deposit",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        setBackImage();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
        pushNotify({
          msg: "Có một đơn napj tiền mới",
          type: "deposit",
          url: "/history/deposit",
          target: [user?.user_ref?.id],
        });
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;
    if (amount < Number(general?.min_deposit || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_deposit
        )}`
      );
    if (amount > Number(general?.max_deposit || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_deposit
        )}`
      );
    onSubmit({ ...values });
    return null;
  };

  const onReceiveBackImages = (images) => {
    setBackImage(images[0]?.url);
  };

  return (
    <div className="container  px-4 mx-auto mt-10">
      <HeaderTop title={i18next.t("deposit")} />
      <div id="withdraw">
        <div className="flex flex-col w-full gap-10  mt-6">
          <div className="flex flex-1">
            <div className="sbui-card w-full h-full">
              <div className="flex justify-center mt-6 ">
                {address && (
                  <QRCode
                    size={256}
                    style={{
                      height: "auto",
                      width: "180px",
                    }}
                    viewBox="0 0 256 256"
                    value={address}
                  />
                )}
              </div>
              <p className="text-sm text-center mt-6">
                {i18next.t("coin_deposit_address")}
              </p>
              <p className="text-sm text-center">{address}</p>
              <div className="flex justify-center mt-6 ">
                <button
                  type="submit"
                  onClick={() => handleCopyLink(address)}
                  className="sbui-btn sbui-btn-primary sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                >
                  <span>{i18next.t("copy_address")}</span>
                </button>
              </div>
              <form
                className="sbui-card-content"
                onSubmit={handleSubmit(onSave)}
              >
                <div className="flex flex-col w-full gap-4">
                  <div>
                    <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                      <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                        <div className="text-gray-600 text-sm">
                          {i18next.t("amount_of_coin_deposit")}
                        </div>
                      </div>
                      <div className="sbui-formlayout__content-container-horizontal">
                        <div className="sbui-input-container">
                          <input
                            type="number"
                            inputMode="decimal"
                            min={0}
                            max={1000000000000}
                            step={0.000001}
                            className="sbui-input sbui-input--medium"
                            placeholder="Please enter quantity"
                            {...register("amount")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <div>
                      <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                        <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                          <div className="text-gray-600 text-sm">
                            {i18next.t("download_payment_details_screenshot")}
                          </div>
                        </div>
                        <div className="sbui-formlayout__content-container-horizontal">
                          <UploadFile
                            onReceiveImages={onReceiveBackImages}
                            image={back_images}
                            folder="payment"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="sbui-btn-container sbui-btn--w-full">
                    <button
                      type="submit"
                      className="sbui-btn sbui-btn-primary sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
                      disabled={!isValid}
                    >
                      <span>{i18next.t("send")}</span>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
