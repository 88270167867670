import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { PaymentApi } from "states/api/payment";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import ListData from "./ListData";

export default function ListHistoryBalance() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, clear },
  } = useStores();

  const [activeTab, setActiveTab] = useState("deposit");

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  const [params, setParams] = useState();

  const { data, refetch } = useQuery(
    ["payment", "list", activeTab, params],
    () =>
      PaymentApi.getList({
        params: {
          page: 1,
          limit: 10,
          type: activeTab,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="container  px-4 mx-auto mt-10">
      <HeaderTop title={i18next.t("transaction_history")} />
      <div className="home-page pt-[46px]">
        <div className="h-[140px] text-[14px] text-white font-700 bg-[#c94848] rounded-[10px] my-[10px] mx-[5px] flex flex-col justify-center items-center">
          <span>{i18next.t("balance")}</span>
          <span>{formatNumber(usdtBalance?.amount, "0,0.00")}</span>
          <span>≈</span>
          <span>{formatNumber(usdtBalance?.convert_amount, "0,0")} VND</span>
        </div>
        <div className="w-[90%] h-[80px] bg-[#191c23] mt-[10px] mx-[auto] flex">
          <div className="flex-1 flex justify-center items-center">
            <span
              className={`w-[90px] text-[18px] text-white text-center bg-black px-[5px] rounded-[20px] border-[1px] border-white ${
                activeTab === "deposit" && "!bg-[#c94848]"
              }`}
              onClick={() => setActiveTab("deposit")}
            >
              {i18next.t("deposit")}
            </span>
          </div>
          <div className="flex-1 flex justify-center items-center">
            <span
              className={`w-[90px] text-[18px] text-white text-center bg-black px-[5px] rounded-[20px] border-[1px] border-white ${
                activeTab === "withdraw" && "!bg-[#c94848]"
              }`}
              onClick={() => setActiveTab("withdraw")}
            >
              {i18next.t("withdraw")}
            </span>
          </div>
        </div>
        <ListData data={data} />
      </div>
      {/* <Pagination /> */}
    </div>
  );
}
