/* eslint-disable react/no-danger */
import React, { useState } from "react";
import moment from "moment";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import MenuChat from "../MenuChat";
import ModalDetailUser from "../ModalDetailUser";
import ModalImage from "./ModalImage";

function MessageLeft({ item, group }) {
  const {
    authStore: { user },
  } = useStores();

  const [openModalDetail, setModalDetail] = useState(false);
  const [openModal, setOpenModal] = useState();

  return (
    <li className="chat-list left" id="1">
      <div className="conversation-list">
        {item.type === "group" && (
          <div
            className="avatar chat-avatar"
            onClick={() => setModalDetail(true)}
          >
            <img src={item?.avatar} alt="" />
          </div>
        )}
        <div className="user-chat-content">
          <div className="ctext-wrap">
            <div className="ctext-wrap-content" id="1">
              {item.type === "group" && (
                <p className="username">{item?.full_name}</p>
              )}
              {item?.file_path && (
                <img
                  src={item?.file_path}
                  alt=""
                  role="presentation"
                  onClick={() => setOpenModal(true)}
                />
              )}
              <p className="mb-0 ctext-content">
                <span
                  dangerouslySetInnerHTML={{
                    __html: item?.message?.split("\n").join("</br>"),
                  }}
                />
                {item?.status === "edit" && (
                  <span className="edit-chat">{i18next.t("fixed")}</span>
                )}
              </p>
            </div>

            {user?.role === "admin" && <MenuChat item={item} />}
          </div>
        </div>
      </div>
      {openModal && (
        <ModalImage
          image={item?.file_path}
          onClose={() => setOpenModal(false)}
        />
      )}
      {openModalDetail && (
        <ModalDetailUser
          data={item}
          group={group}
          onClose={() => setModalDetail(false)}
        />
      )}
    </li>
  );
}

export default MessageLeft;
