import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { GamePlayerApi } from "states/api/gamePlayer";
import { useQuery } from "@tanstack/react-query";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";

const HallBuy = observer(() => {
  const { data, refetch } = useQuery(
    ["game-player", "list"],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 15,
          bet_game: "sell",
          type: "p2p",
          status: "bet",
          sort_field: "open",
          order_by: "asc",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      {data?.data?.map((item) => (
        <div
          key={item?.id}
          className="bg-[#191c23] rounded-[10px] p-[10px] mx-[10px] mb-[10px] flex flex-col"
        >
          <div className="w-[100%] text-[15px] text-[#9b9b9b] flex">
            <span className="text-white flex-1 flex items-center">
              {item?.user?.username}
            </span>
            <span className="flex-1 flex items-center">
              {i18next.t("quantity")} {formatNumber(item?.amount, "0,0.[00]")}
              USDT
            </span>
          </div>
          <div className="w-[100%] text-[15px] text-[#9b9b9b] flex">
            <span className="flex-1 flex items-center">
              {i18next.t("purchase_price")}{" "}
              {formatNumber(item?.open, "0,0.[00]")}
            </span>
            <span className="flex-1 flex items-center">
              {i18next.t("sell_no_yet")}{" "}
              {formatNumber(item?.amount_pending, "0,0.[00]")} USDT
            </span>
          </div>
        </div>
      ))}
    </div>
  );
});

export default HallBuy;
