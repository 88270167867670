import React from "react";
import { observer } from "mobx-react-lite";
import { Portal } from "react-portal";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import i18next from "i18next";

const PopupAvatar = observer(({ onOpen, onClose, setAvatarUrl }) => {
  const {
    authStore: { avatars },
  } = useStores();

  return (
    <Portal>
      <div className="custom-modal">
        <div className="custom-mask" onClick={onClose} />
        <div className="custom-modal-body !w-full max-h-[80%] text-[16px] !bg-white !rounded-b-[0] !top-[unset] !right-[0] !bottom-[0] !left-[0] !translate-y-[0] !translate-x-[0]">
          <div className="custom-modal-header !h-[48px] !leading-[48px] text-black text-center relative">
            {i18next.t("avatar")}
            <FontAwesomeIcon
              icon={faClose}
              color="#c8c9cc"
              className="w-[22px] h-[48px] absolute right-[10px]"
              onClick={onClose}
            />
          </div>
          <div className="custom-modal-content h-[500px] text-black mx-[auto] flex flex-wrap overflow-y-auto">
            {avatars.map((avatar) => (
              <div
                key={avatar?.id}
                className="py-[16px] px-[8px] border-[1px] border-[#13171a] basis-1/5 flex justify-center items-center"
                onClick={() => {
                  setAvatarUrl(avatar.image_url);
                  onClose();
                }}
              >
                <img
                  src={avatar.image_url}
                  alt=""
                  className="w-[45px] h-[45px]"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default PopupAvatar;
