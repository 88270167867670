import React from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import i18next from "i18next";

const FinListPage = observer(() => (
  <>
    <HeaderTop title={i18next.t("profit_wallet")} />
    <div className="home-page !bg-[#13171a] pt-[46px]" />
  </>
));

export default FinListPage;
