import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";

import i18next from "i18next";

const defaultHeight = 2400;

const Notice = observer(() => {
  const [tranY, setTranY] = useState(0);
  const listText = [
    ` hoanganh******@gmail.com* ${i18next.t(
      "sell_on"
    )} 48791USDT 40 ${i18next.t("prev_minute")} `,
    ` tuanngoc*******@gmail.com  ${i18next.t(
      "sell_on"
    )} 4191USDT 17 ${i18next.t("prev_minute")} `,
    ` hoang96******@gmail.com ${i18next.t("sell_on")} 37921USDT 16 ${i18next.t(
      "prev_minute"
    )} `,
    ` long44******@gmail.com ${i18next.t("sell_on")} 12246USDT 37 ${i18next.t(
      "prev_minute"
    )} `,
    ` ngoc451****@gmail.com ${i18next.t("sell_on")} 1759USDT 40 ${i18next.t(
      "prev_minute"
    )} `,
    ` minhngoc***@gmail.com ${i18next.t("sell_on")} 20408USDT 42 ${i18next.t(
      "prev_minute"
    )} `,
    ` phuclong*****@gmail.com ${i18next.t("sell_on")} 9366USDT 38 ${i18next.t(
      "prev_minute"
    )} `,
    ` hoang451*****@gmail.com ${i18next.t("sell_on")} 41397USDT 26 ${i18next.t(
      "prev_minute"
    )} `,
    ` oanhpham***@gmail.com ${i18next.t("sell_on")} 30381USDT 46 ${i18next.t(
      "prev_minute"
    )} `,
    ` cien45*****@gmail.com ${i18next.t("sell_on")} 33838USDT 43 ${i18next.t(
      "prev_minute"
    )} `,
    ` hoaanh1*******@gmail.com ${i18next.t("sell_on")} 32114USDT 46 ${i18next.t(
      "prev_minute"
    )} `,
    ` longanh*****@gmail.com ${i18next.t("sell_on")} 46151USDT 38 ${i18next.t(
      "prev_minute"
    )} `,
    ` phuocpham****@gmail.com ${i18next.t("sell_on")} 49528USDT 12 ${i18next.t(
      "prev_minute"
    )} `,
    ` hunhanh45******@gmail.com ${i18next.t(
      "sell_on"
    )} 17489USDT 56 ${i18next.t("prev_minute")} `,
    ` maianh451***@gmail.com ${i18next.t("sell_on")} 4066USDT 44 ${i18next.t(
      "prev_minute"
    )} `,
    ` phongle****@gmail.com ${i18next.t("sell_on")} 36528USDT 41 ${i18next.t(
      "prev_minute"
    )} `,
    ` phuoc****@gmail.com ${i18next.t("sell_on")} 12185USDT 56 ${i18next.t(
      "prev_minute"
    )} `,
    ` huynhanh******@gmail.com ${i18next.t("sell_on")} 47808USDT 24 ${i18next.t(
      "prev_minute"
    )} `,
    ` long451*****@gmail.com ${i18next.t("sell_on")} 43202USDT 35 ${i18next.t(
      "prev_minute"
    )} `,
    ` phuonghoang45*******@gmail.com ${i18next.t(
      "sell_on"
    )} 12086USDT 22 ${i18next.t("prev_minute")} `,
    ` hoangngoc****@gmail.com ${i18next.t(
      "sell_on"
    )} 54212.00USDT 5 Ngày Trước `,
    ` thienanh****@gmail.com ${i18next.t(
      "sell_on"
    )} 65653.00USDT 3 Ngày Trước `,
    ` phuocanh***@gmail.com ${i18next.t("sell_on")} 25656.00USDT 2 Ngày Trước `,
    ` tinle45***@gmail.com ${i18next.t("sell_on")} 9823.00USDT 1 Ngày Trước `,
    ` 1234***@gmail.com ${i18next.t("sell_on")} 10000.00USDT 3 Ngày Trước `,
    ` phuonganh*****@gmail.com ${i18next.t(
      "sell_on"
    )} 87446.00USDT 1 Ngày Trước `,
    ` huyanhle96***@gmail.com ${i18next.t(
      "sell_on"
    )} 57871.00USDT 5 Ngày Trước `,
    ` truonganh45***@gmail.com ${i18next.t(
      "sell_on"
    )} 542454.00USDT 5 Ngày Trước `,
    ` phucanh45***@gmail.com ${i18next.t("sell_on")} 5656.00USDT 3 Ngày Trước `,
    ` vantran****@gmail.com ${i18next.t("sell_on")} 35000.00USDT 2 Ngày Trước `,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTranY((evl) => {
        if (evl <= -2400) return 0;
        return evl - 60;
      });
    }, 3600);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="hallswipe van-swipe">
      <div
        className="van-swipe__track van-swipe__track--vertical"
        style={{
          height: defaultHeight,
          transitionDuration: "500ms",
          transform: `translateY(${tranY}px)`,
        }}
      >
        {listText?.map((item, index) => (
          <div
            key={`item-${index?.toString()}`}
            className="van-swipe-item"
            style={{ height: 60 }}
          >
            <div>{item}</div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Notice;
