import React from "react";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { AuthApi } from "states/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import Loading from "_common/component/Loading";

import iconUser from "assets/img/icon/user_input.png";
import iconPwd from "assets/img/icon/pwd_input.png";
import InputPassword from "_common/component/InputPassword";

export default function LoginForm() {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const schema = yup
    .object({
      username: yup.string().required(i18next.t("verify_require")),
      password: yup.string().required(i18next.t("verify_require")),
    })
    .required();

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-sign-in" onSubmit={handleSubmit(handleLogin)}>
      {isLoading && <Loading />}
      <div className="sbui-space-col sbui-space-y-6">
        <div className="custom-input">
          <img src={iconUser} alt="" className="!w-[25px] !h-[25px]" />
          <input
            type="text"
            placeholder={i18next.t("verify_require_email")}
            {...register("username")}
          />
        </div>
        <div className="custom-input">
          <img src={iconPwd} alt="" className="!w-[25px] !h-[25px]" />
          <InputPassword
            type="password"
            placeholder={i18next.t("verify_require_password")}
            autoComplete="current-password"
            {...register("password")}
          />
        </div>
        <div className="flex justify-end">
          <Link to="/auth/forgot" className="text-[#c94848]">
            {i18next.t("forgot_password")}
          </Link>
        </div>
        <button
          type="submit"
          className="custom-btn"
          disabled={!isValid || isLoading}
        >
          {i18next.t("login")}
        </button>
        <div className="text-[#6b7280] flex justify-center gap-[10px]">
          <Link to="/auth/register" className="text-[#c94848]">
            {i18next.t("note_19")}
          </Link>
        </div>
      </div>
    </form>
  );
}
