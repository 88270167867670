import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import Loading from "_common/component/Loading";
import i18next from "i18next";

import iconUser from "assets/img/icon/user_input.png";

import ActiveCode from "./ActiveCode";

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require_username")),
  })
  .required();

export default function ForgotPasswordForm({ type }) {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [isSuccess, setIsSuccess] = useState(false);

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.forgot(variables),
    {
      onSuccess: (res) => {
        setIsSuccess(true);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  if (isSuccess) return <ActiveCode />;

  return (
    <form id="auth-sign-up" onSubmit={handleSubmit(handleRegister)}>
      {isLoading && <Loading />}
      <div className="sbui-space-col sbui-space-y-6">
        <div className="custom-input">
          <img src={iconUser} alt="" className="!w-[25px] !h-[25px]" />
          <input
            type="text"
            placeholder="Vui lòng nhập email"
            {...register("username")}
          />
        </div>
        <button
          type="submit"
          className="custom-btn"
          disabled={!isValid || isLoading}
        >
          Gửi yêu cầu
        </button>
      </div>
    </form>
  );
}
