import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faAngleRight,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";

// icon
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { observer } from "mobx-react-lite";
import i18next from "i18next";

import iconSetting from "assets/img/icon/setting.png";
import iconNotice from "assets/img/icon/notice (1).png";
import icon1 from "assets/img/icon/fund.png";
import icon2 from "assets/img/icon/team.png";
import icon3 from "assets/img/icon/bind.png";
import icon4 from "assets/img/icon/account.png";
import icon5 from "assets/img/icon/fin.png";
import icon6 from "assets/img/icon/exit.png";
import { formatNumber } from "_common/utils/formatValue";
import PopupInterestRate from "./Popup/PopupInterestRate";
import PopupUpgrade from "./Popup/PopupUpgrade";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, game_wallet, general, clear },
  } = useStores();

  const [isShowPopupUpgrade, setIsShowPopupUpgrade] = useState(false);
  const [isShowPopupInterestRate, setIsShowPopupInterestRate] = useState(false);

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  const vndBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "vnd"),
    [user]
  );

  useEffect(() => {
    onGetProfile();
  }, []);

  return (
    <>
      <div className="home-page !bg-[#13171a]">
        <div className="p-[5px] flex">
          <span className="w-[50%] text-[#ccc]">
            {i18next.t("user_center")}
          </span>
          <div className="w-[50%] flex justify-end">
            <Link to="/member/info">
              <img src={iconSetting} alt="" className="w-[25px] h-[25px]" />
            </Link>
            <Link to="/member/introduction">
              <img src={iconNotice} alt="" className="w-[25px] h-[25px]" />
            </Link>
          </div>
        </div>
        <div className="pl-[130px] pt-[10px]">
          <div className="flex gap-[10px]">
            <div className="w-[82px] h-[82px] bg-[#eff0f2] p-[6px] rounded-[50%]">
              <img
                src={user?.avatar_url}
                alt=""
                className="w-full h-full rounded-[50%]"
              />
            </div>
            <div className="text-[14px] text-[#e7e7e7] flex flex-col justify-end">
              <span>VIP{user?.level ?? 0}</span>
              <span
                className="bg-[#c94848] p-[5px] rounded-[2px]"
                // onClick={() => setIsShowPopupUpgrade(true)}
              >
                {i18next.t("upgrade")}
              </span>
            </div>
          </div>
          <span className="text-[21px] text-white">{user?.username}</span>
        </div>
        <div className="w-[95%] text-[18px] text-[#e7e7e7] bg-[#191c23] p-[20px] rounded-[10px] shadow-[0_1px_3px_hsla(0,0%,85.9%,.8588235294117647)] mt-[50px] mx-[auto]">
          <div className="flex items-center">
            <span className="w-[80%]">
              {i18next.t("balance")}(USDT){" "}
              <b>{formatNumber(usdtBalance?.amount, "0,0.00")}</b>
            </span>
            <Link
              to="/member/recharge"
              className="w-[20%] text-[14px] text-center bg-[#c94848] py-[5px] rounded-[2px]"
            >
              {i18next.t("deposit")}
            </Link>
          </div>
          <div className="mt-[15px] flex items-center">
            <span className="w-[80%]">
              {i18next.t("balance")}(VND){" "}
              <b>{formatNumber(vndBalance?.amount, "0,0.00")}</b>
            </span>
            <Link
              to="/board/withdraw"
              className="w-[20%] text-[14px] text-center bg-[#c94848] py-[5px] rounded-[2px]"
            >
              {i18next.t("withdraw")}
            </Link>
          </div>
          <div className="mt-[15px] flex items-center">
            <span className="w-[80%]">
              {i18next.t("profit_wallet")}(VND) <b>0.00</b>
            </span>
            <Link
              to="/member/fin-list"
              className="w-[20%] text-[14px] text-center bg-[#c94848] py-[5px] rounded-[2px]"
            >
              {i18next.t("detail")}
            </Link>
          </div>
        </div>
        <div className="w-[95%] text-[17px] text-[#e7e7e7] bg-[#191c23] p-[10px] rounded-[10px] mt-[10px] mx-[auto] flex">
          <div className="py-[16px] flex-1 flex flex-col items-center">
            <span>{formatNumber(user?.bill_deposit, "0,0.000")}</span>
            <span className="text-[12px] text-[#ababab]">
              {i18next.t("deposit_amount")} (USDT)
            </span>
          </div>
          <div className="py-[16px] flex-1 flex flex-col items-center">
            <span>{formatNumber(user?.win_total, "0,0.000")}</span>
            <span className="text-[12px] text-[#ababab]">
              {i18next.t("sell_money")} (USDT)
            </span>
          </div>
          <div className="py-[16px] flex-1 flex flex-col items-center">
            <span>{formatNumber(user?.profit_bet_total, "0,0.000")}</span>
            <span className="text-[12px] text-[#ababab]">
              {i18next.t("profit")} (USDT)
            </span>
          </div>
        </div>
        <div className="w-[95%] text-[16px] text-[#e7e7e7] bg-[#191c23] p-[10px] rounded-[10px] mt-[10px] mx-[auto]">
          <Link
            to="/member/wallet"
            className="w-full py-[17px] border-b-[0.5px] border-[#13171a] flex items-center gap-[5px]"
          >
            <img src={icon1} alt="" className="w-[16px] h-[16px]" />
            <span className="flex-1">{i18next.t("my_asset")}</span>
            <FontAwesomeIcon icon={faChevronRight} color="#969799" />
          </Link>
          <Link
            to="/member/my-team"
            className="w-full py-[17px] border-b-[0.5px] border-[#13171a] flex items-center gap-[5px]"
          >
            <img src={icon2} alt="" className="w-[16px] h-[16px]" />
            <span className="flex-1">{i18next.t("my_team")}</span>
            <FontAwesomeIcon icon={faChevronRight} color="#969799" />
          </Link>
          <Link
            to="/member/bind-account"
            className="w-full py-[17px] border-b-[0.5px] border-[#13171a] flex items-center gap-[5px]"
          >
            <img src={icon3} alt="" className="w-[16px] h-[16px]" />
            <span className="flex-1">{i18next.t("bid_account")}</span>
            <FontAwesomeIcon icon={faChevronRight} color="#969799" />
          </Link>
          <Link
            to="/board/history"
            className="w-full py-[17px] border-b-[0.5px] border-[#13171a] flex items-center gap-[5px]"
          >
            <img src={icon4} alt="" className="w-[16px] h-[16px]" />
            <span className="flex-1">{i18next.t("detail_transaction")}</span>
            <FontAwesomeIcon icon={faChevronRight} color="#969799" />
          </Link>
          <div
            className="w-full py-[17px] border-b-[0.5px] border-[#13171a] flex items-center gap-[5px]"
            onClick={() => setIsShowPopupInterestRate(true)}
          >
            <img src={icon5} alt="" className="w-[16px] h-[16px]" />
            <span className="flex-1">{i18next.t("profit_wallet")}</span>
            <FontAwesomeIcon icon={faChevronRight} color="#969799" />
          </div>
          <div
            className="w-full py-[17px] flex items-center gap-[5px]"
            onClick={onLogout}
          >
            <img src={icon6} alt="" className="w-[16px] h-[16px]" />
            <span className="flex-1">{i18next.t("logout")}</span>
            <FontAwesomeIcon icon={faChevronRight} color="#969799" />
          </div>
        </div>
      </div>
      {isShowPopupUpgrade && (
        <PopupUpgrade
          onOpen={isShowPopupUpgrade}
          onClose={() => setIsShowPopupUpgrade(false)}
        />
      )}
      {isShowPopupInterestRate && (
        <PopupInterestRate
          onOpen={isShowPopupInterestRate}
          onClose={() => setIsShowPopupInterestRate(false)}
        />
      )}
    </>
  );
});

export default MemberPage;
