import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

export default function HistoryData() {
  const {
    authStore: { user },
  } = useStores();
  const [active, setActive] = useState("bet");

  const { data, refetch } = useQuery(
    ["game-player", "list", active],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 10,
          type: "trade",
          user_id: user?.id,
          status: active !== "all" ? active : "",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, [active]);

  return (
    <>
      <div className="h-[44px] bg-[#191c23] text-[14px] text-[#ccc] flex">
        <span
          className={`w-[25%] leading-[44px] text-center relative ${
            active === "all" && "!text-[#c94848]"
          }`}
          onClick={() => setActive("all")}
        >
          {i18next.t("all")}
          {active === "all" && (
            <span className="w-[40px] h-[3px] bg-[#ee0a24] absolute bottom-[0] left-[35%]" />
          )}
        </span>
        <span
          className={`w-[25%] leading-[44px] text-center relative ${
            active === "bet" && "!text-[#c94848]"
          }`}
          onClick={() => setActive("bet")}
        >
          {i18next.t("pending")}
          {active === "bet" && (
            <span className="w-[40px] h-[3px] bg-[#ee0a24] absolute bottom-[0] left-[35%]" />
          )}
        </span>
        <span
          className={`w-[25%] leading-[44px] text-center relative ${
            active === "lose" && "!text-[#c94848]"
          }`}
          onClick={() => setActive("lose")}
        >
          {i18next.t("lose")}
          {active === "lose" && (
            <span className="w-[40px] h-[3px] bg-[#ee0a24] absolute bottom-[0] left-[35%]" />
          )}
        </span>
        <span
          className={`w-[25%] leading-[44px] text-center relative ${
            active === "win" && "!text-[#c94848]"
          }`}
          onClick={() => setActive("win")}
        >
          {i18next.t("complete")}
          {active === 4 && (
            <span className="w-[40px] h-[3px] bg-[#ee0a24] absolute bottom-[0] left-[35%]" />
          )}
        </span>
      </div>
      <div className=" px-2 mx-auto mt-5 w-full">
        <div className="list-history-data">
          {get(data, "data", [])?.map((item) => (
            <div className="item" key={item?.id}>
              <div className="flex  justify-between items-center gap-2 mt-2">
                <div className="flex flex-col  w-full">
                  <h5 className="text-lg font-bold">
                    {i18next.t("order_no")} :{" "}
                    {item?.created_at &&
                      moment(item?.created_at).format("YYYYMMDDHHmmss")}
                    {item?.id}
                  </h5>
                  <p className="text-sm font-bold">
                    {i18next.t("commercial_account")} :{" "}
                    {item?.user?.username_display}
                  </p>
                  <p className="text-sm font-bold">
                    {i18next.t("amount_sell")} :{" "}
                    {formatNumber(item?.amount, "0,0.[0000]")}
                  </p>
                  <p className="text-sm font-bold">
                    {i18next.t("saleprice")} : {formatNumber(item?.open)} VNĐ
                  </p>
                  <p className="text-sm font-bold">
                    {i18next.t("money_amount")} :{" "}
                    {formatNumber(Number(item?.amount) * Number(item?.open))}{" "}
                    VNĐ
                  </p>
                  <p className="text-sm font-bold">
                    {i18next.t("status")} : {i18next.t(item?.status)}{" "}
                    {i18next.t("time_to_sell")}{" "}
                    {item?.created_at &&
                      moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
