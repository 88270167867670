import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useStores } from "_common/hooks";

import icon1 from "assets/img/icon/buy.png";
import icon2 from "assets/img/icon/sell.png";
import AddBankForm from "pages/Wallet/Bank/AddBankForm";
import { formatNumber } from "_common/utils/formatValue";
import { NotificationManager } from "react-notifications";
import KYC from "pages/Member/KYC";
import { useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";

import HallBuy from "./HallBuy";
import HallSell from "./HallSell";
import Notice from "./Notice";

const HallPage = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, my_bank, usdt_wallet },
  } = useStores();

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  if (!my_bank?.id) return <AddBankForm />;
  if (user?.verify_msg) return <KYC />;

  return (
    <>
      <HeaderTop title={i18next.t("transaction")} isBack={false} />
      <div className="home-page !bg-[#13171a] pt-[46px]">
        <div className="h-[140px] text-white bg-[#c94848] rounded-[10px] my-[10px] mx-[5px] flex flex-col justify-center items-center">
          <span className="text-[14px]">{i18next.t("balance")} (USDT)</span>
          <u className="text-[18px] font-700">
            {formatNumber(usdt_wallet?.amount, "0,0.[00]")}
          </u>
        </div>
        <div className="m-[10px] flex gap-[10px]">
          <Link
            to="/hall"
            onClick={() =>
              NotificationManager.info(
                "Tài khoản trước mắt cần phải nâng cấp lên thương gia"
              )
            }
            className="text-[12px] text-[#ccc] bg-[#191c23] p-[10px] flex-1 flex justify-center items-center gap-[10px]"
          >
            <img src={icon1} alt="" className="w-[50px] h-[50px]" />
            <span>{i18next.t("buy")}</span>
          </Link>
          <Link
            to="/sell"
            className="text-[12px] text-[#ccc] bg-[#191c23] p-[10px] flex-1 flex justify-center items-center gap-[10px]"
          >
            <img src={icon2} alt="" className="w-[50px] h-[50px]" />
            <span>{i18next.t("sell")}</span>
          </Link>
        </div>
        <Notice />
        <div className="m-[10px] bg-[#191c23] flex gap-[10px]">
          <div className="text-[14px] font-[700] p-[10px] flex-1">
            <span
              className={`h-[44px] text-black bg-white px-[15px] rounded-[10px] flex justify-center items-center ${
                activeTab === 1 && "!text-white !bg-[#c94848]"
              }`}
              onClick={() => setActiveTab(1)}
            >
              {i18next.t("sales_order")}
            </span>
          </div>
          <div className="text-[14px] font-[700] p-[10px] flex-1">
            <span
              className={`h-[44px] text-black bg-white px-[15px] rounded-[10px] flex justify-center items-center ${
                activeTab === 2 && "!text-white !bg-[#c94848]"
              }`}
              onClick={() => setActiveTab(2)}
            >
              {i18next.t("sell_​​to_merchants")}
            </span>
          </div>
        </div>
        {activeTab === 1 ? <HallBuy /> : <HallSell />}
      </div>
    </>
  );
});

export default HallPage;
