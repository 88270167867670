import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Portal } from "react-portal";
import i18next from "i18next";

const PopupInterestRate = observer(({ onOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <Portal>
      <div className="custom-modal">
        <div className="custom-mask" onClick={onClose} />
        <div className="custom-modal-body">
          <div className="custom-modal-header text-[16px] text-[#aaa] text-center pt-[20px]">
            {i18next.t("wallet_interest_rate")}
          </div>
          <div className="custom-modal-content w-[85%] mx-[auto] flex flex-col">
            <div className="py-[20px] flex flex-col items-center">
              <div
                className={`w-[150px] text-[14px] text-black text-center px-[15px] m-[10px] bg-[#ccc] rounded-[2px] ${
                  activeTab === 1 && "!bg-[#5ac16e]"
                }`}
                onClick={() => setActiveTab(1)}
              >
                7 {i18next.t("day")},0.5% {i18next.t("profit_rate")}
              </div>
              <div
                className={`w-[150px] text-[14px] text-black text-center px-[15px] m-[10px] bg-[#ccc] rounded-[2px] ${
                  activeTab === 2 && "!bg-[#5ac16e]"
                }`}
                onClick={() => setActiveTab(2)}
              >
                15 {i18next.t("day")},0.8% {i18next.t("profit_rate")}
              </div>
              <div
                className={`w-[150px] text-[14px] text-black text-center px-[15px] m-[10px] bg-[#ccc] rounded-[2px] ${
                  activeTab === 3 && "!bg-[#5ac16e]"
                }`}
                onClick={() => setActiveTab(3)}
              >
                30 {i18next.t("day")},1.3% {i18next.t("profit_rate")}
              </div>
              <div
                className={`w-[150px] text-[14px] text-black text-center px-[15px] m-[10px] bg-[#ccc] rounded-[2px] ${
                  activeTab === 4 && "!bg-[#5ac16e]"
                }`}
                onClick={() => setActiveTab(4)}
              >
                90 {i18next.t("day")},2% {i18next.t("profit_rate")}
              </div>
            </div>
            <input
              type="text"
              defaultValue="0"
              className="custom-input !w-full text-white !bg-[#353c4a] !py-[10px] !rounded-[0] !mb-[30px]"
            />
          </div>
          <div className="custom-modal-footer border-t-[0.5px] border-[#13171a] flex">
            <span
              className="w-[50%] h-[48px] leading-[48px] text-[#ccc] text-center border-r-[0.5px] border-[#13171a]"
              onClick={onClose}
            >
              {i18next.t("cancel")}
            </span>
            <span
              className="w-[50%] h-[48px] leading-[48px] text-[#13c465] text-center"
              onClick={onClose}
            >
              {i18next.t("send")}
            </span>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default PopupInterestRate;
