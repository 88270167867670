import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";

import PopupHallSell from "./Popup/PopupHallSell";

const HallSell = observer(() => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [detail, setDetail] = useState();

  const { data, refetch } = useQuery(
    ["game-sell", "list"],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 15,
          type: "p2p",
          bet_game: "buy",
          status: "bet",
          sort_field: "open",
          order_by: "asc",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleOpenModal = (item) => {
    setDetail(item);
    setIsShowPopup(true);
  };

  const handleCloseModal = (item) => {
    setDetail();
    setIsShowPopup(false);
  };

  return (
    <>
      {data?.data?.map((item) => (
        <div
          key={item?.id}
          className="bg-[#191c23] rounded-[10px] p-[10px] mx-[10px] mb-[10px] flex flex-col"
        >
          <div className="w-[100%] text-[15px] text-[#9b9b9b] flex">
            <span className="text-white flex-1 flex items-center">
              {item?.user?.username}
            </span>
            <span className="flex-1 flex items-center">
              {i18next.t("quantity")} {formatNumber(item?.amount, "0,0.00")}{" "}
              USDT
            </span>
          </div>
          <div className="w-[100%] text-[15px] text-[#9b9b9b] flex">
            <span className="flex-1 flex items-center">
              {i18next.t("price_sell")} {formatNumber(item?.open, "0,0.00")}
            </span>
            <span className="flex-1 flex items-center">
              {i18next.t("purchase_no_yet")}{" "}
              {formatNumber(item?.amount_pending, "0,0.00")} USDT
            </span>
          </div>
          <div className="w-[100%] text-[15px] text-[#9b9b9b] flex">
            <span className="flex-1 flex items-center">
              {i18next.t("money_level")}{" "}
              {formatNumber(item?.min_amount, "0,0.00")}-
              {formatNumber(item?.max_amount, "0,0.00")} USDT
            </span>
            <div className="flex-1 flex justify-end items-center">
              <span
                className="w-[80px] h-[28px] text-[14px] text-white bg-[#c94848] rounded-[10px] flex justify-center items-center"
                onClick={() => handleOpenModal(item)}
              >
                {i18next.t("go_sell")}
              </span>
            </div>
          </div>
        </div>
      ))}
      {isShowPopup && (
        <PopupHallSell
          detail={detail}
          onOpen={isShowPopup}
          onClose={() => handleCloseModal(false)}
        />
      )}
    </>
  );
});

export default HallSell;
