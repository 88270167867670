import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import { useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";

const WalletPage = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, game_wallet, general, clear },
  } = useStores();

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  const vndBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "vnd"),
    [user]
  );

  useEffect(() => {
    queryClient.invalidateQueries(["get_profile"]);
  }, []);

  return (
    <>
      <HeaderTop title={i18next.t("my_asset")} />
      <div className="home-page !bg-[#13171a] pt-[46px]">
        <div className="h-[140px] text-white bg-[#c94848] rounded-[10px] my-[10px] mx-[5px] flex flex-col justify-center items-center">
          <span className="text-[14px]">{i18next.t("balance")}:</span>
          <span className="text-[14px]">
            {formatNumber(usdtBalance?.amount, "0,0.00")} (USDT)
          </span>
        </div>
        <div className="flex justify-center">
          <Link
            to="/member/recharge"
            className="w-[85%] text-[18px] text-white text-center bg-[#c94848] rounded-[20px] p-[10px] mx-[auto] my-[15px]"
          >
            {i18next.t("deposit")}
          </Link>
        </div>
        <div className="p-[20px] mt-[50px]">
          <div className="text-[14px] text-white py-[5px] border-b-[1px] border-white flex items-center">
            <span className="w-[80%]">{i18next.t("deposited_amount")}</span>
            <span className="w-[20%] text-right">
              {formatNumber(user?.bill_deposit, "0,0.000")} USDT
            </span>
          </div>
          <div className="text-[14px] text-white py-[5px] border-b-[1px] border-white flex items-center">
            <span className="w-[80%]">{i18next.t("amount_sold")}</span>
            <span className="w-[20%] text-right">
              {formatNumber(user?.sell_total, "0,0.000")} USDT
            </span>
          </div>
          <div className="text-[14px] text-white py-[5px] border-b-[1px] border-white flex items-center">
            <span className="w-[80%]">{i18next.t("profit_received")}</span>
            <span className="w-[20%] text-right">
              {formatNumber(user?.profit_total, "0,0.000")} USDT
            </span>
          </div>
        </div>
      </div>
    </>
  );
});

export default WalletPage;
