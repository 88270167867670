import React, { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next, { use } from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useNotify } from "_common/component/NotifyProvider";

const schema = yup
  .object({
    amount: yup.number().required(i18next.t("verify_require")),
    otp: yup.string(),
  })
  .required();

export default function WithdrawSubmit() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { pushNotify } = useNotify();

  const symbol = params.get("symbol");

  const {
    authStore: { user, general, my_bank },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.withdraw({
        params: {
          ...variables,
          symbol: "vnd",
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
        pushNotify({
          msg: "Có một đơn rút tiền mới",
          type: "withdraw",
          url: "/history/withdrawal",
          target: [user?.user_ref?.id],
        });
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;

    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_withdraw
        )}`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_withdraw
        )}`
      );
    onSubmit({ ...values, bank_id: my_bank?.id });
    return null;
  };

  return (
    <>
      <HeaderTop title={i18next.t("withdraw")} />
      <form
        className="home-page !bg-[#13171a] pt-[46px]"
        onSubmit={handleSubmit(onSave)}
      >
        <div className="w-[95%] text-[14px] text-[#ccc] bg-[#191c23] p-[10px] rounded-[10px] mt-[15px] mx-[auto]">
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%] text-[16px] font-bold">
              {i18next.t("name")}
            </span>
            <span className="w-[75%] text-[14px] text-[#bbb]">
              {my_bank?.bank_owner}
            </span>
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%] text-[16px] font-bold">
              {i18next.t("phone")}
            </span>
            <span className="w-[75%] text-[14px] text-[#bbb]">
              {my_bank?.bank_phone}
            </span>
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%] text-[16px] font-bold">
              {i18next.t("bank_number")}
            </span>
            <span className="w-[75%] text-[14px] text-[#bbb]">
              {my_bank?.bank_number}
            </span>
          </div>
          <div className="py-[10px] px-[16px] border-b-[0.5px] border-[#13171a] flex items-center">
            <span className="w-[25%] text-[16px] font-bold">
              {i18next.t("bank_name")}
            </span>
            <span className="w-[75%] text-[14px] text-[#bbb]">
              {my_bank?.bank_name}
            </span>
          </div>
          <div className="py-[10px] px-[16px] flex items-center">
            <span className="w-[25%] text-[16px] font-bold">
              {i18next.t("money_amount")}
            </span>
            <input
              type="number"
              inputMode="decimal"
              step={0.000001}
              placeholder={i18next.t("withdrawal_amount")}
              className="custom-input !w-[75%] !text-[14px] text-[#bbb] !pl-[0]"
              {...register("amount")}
            />
          </div>
        </div>
        <button
          type="submit"
          className="button w-[85%] text-[18px] text-white text-center !bg-[#c94848] rounded-[25px] p-[10px] mx-[auto] my-[15px]"
        >
          {i18next.t("send")}
        </button>
      </form>
    </>
  );
}
