import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import Loading from "_common/component/Loading";
import i18next from "i18next";

import iconUser from "assets/img/icon/user_input.png";
import iconPwd from "assets/img/icon/pwd_input.png";
import iconSMS from "assets/img/icon/smscode_input.png";
import iconInvite from "assets/img/icon/invite_input.png";
import InputPassword from "_common/component/InputPassword";

export default function RegisterForm({ type }) {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const schema = yup
    .object({
      username: yup.string().required(i18next.t("verify_require_username")),
      password: yup.string().required(i18next.t("verify_require_password")),
      confirmed: yup
        .string()
        .required(i18next.t("verify_require_confirm_password"))
        .oneOf([yup.ref("password")], i18next.t("verify_unmatch_password")),
    })
    .required();

  const ref_no = params?.get("ref_no");
  const [email, setEmail] = useState();
  const [count, setCount] = useState(0);

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data);
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const { mutate: senOTP, isLoading: otpLoading } = useMutation(
    (variables) => AuthApi.sendOTPMail(variables),
    {
      onSuccess: (res, variables) => {
        setCount(60);
        NotificationManager.info(
          `Mã OTP đã được gửi về địa chỉ email ${variables?.email}`
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "username") {
        setEmail(value?.username);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  useEffect(() => {
    if (!ref_no) return;
    setValue("ref_no", ref_no);
  }, [ref_no]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [count]);

  const handleSubmitOTP = () => {
    if (otpLoading) return null;
    if (!email)
      return NotificationManager.error("Vui lòng nhập một địa chỉ email");
    senOTP({
      email,
    });
    return null;
  };

  return (
    <form id="auth-sign-up" onSubmit={handleSubmit(handleRegister)}>
      {(isLoading || otpLoading) && <Loading />}
      <div className="sbui-space-col sbui-space-y-6">
        <div className="custom-input">
          {type === "email" ? (
            <img src={iconUser} alt="" className="!w-[25px] !h-[25px]" />
          ) : (
            <p className="text-[18px] text-white">+84</p>
          )}
          <input
            type="email"
            inputMode="email"
            placeholder="Vui lòng nhập email"
            {...register("username")}
          />
          {!count ? (
            <span className="btn-primary" onClick={() => handleSubmitOTP()}>
              {i18next.t("send_code")}
            </span>
          ) : (
            <span className="btn-primary">{count}s</span>
          )}
        </div>
        <div className="custom-input !pr-[0]">
          <img src={iconSMS} alt="" className="!w-[25px] !h-[25px]" />
          <input
            type="text"
            placeholder={i18next.t("receive_otp")}
            autoComplete="current-password"
            {...register("otp")}
          />
          {/* <img
            src="https://www.veindirps.com/captcha/?rand=0.29685425867633985"
            alt=""
          /> */}
        </div>
        <div className="custom-input">
          <img src={iconPwd} alt="" className="!w-[25px] !h-[25px]" />
          <InputPassword
            type="password"
            placeholder={i18next.t("verify_require_password")}
            autoComplete="current-password"
            {...register("password")}
          />
        </div>
        <div className="custom-input">
          <img src={iconPwd} alt="" className="!w-[25px] !h-[25px]" />
          <InputPassword
            type="password"
            placeholder={i18next.t("confirmed_password")}
            autoComplete="current-password"
            {...register("confirmed")}
          />
        </div>
        {Number(authStore.general?.allow_ref_no) === 1 && (
          <div className="custom-input">
            <img src={iconInvite} alt="" className="!w-[25px] !h-[25px]" />
            <input
              type="text"
              placeholder={i18next.t("ref_text")}
              disabled={!!ref_no}
              {...register("ref_no")}
            />
          </div>
        )}
        <button
          type="submit"
          className="custom-btn"
          disabled={!isValid || isLoading}
        >
          {i18next.t("register")}
        </button>
        <div className="text-[#6b7280] flex justify-center">
          <Link to="/auth/login" className="text-[#6b7280]">
            {i18next.t("back_login")}
          </Link>
        </div>
      </div>
    </form>
  );
}
