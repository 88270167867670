import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { useNotify } from "_common/component/NotifyProvider";
import { useSearchParams } from "react-router-dom";
import ModalPassword from "pages/Home/ModalPassword";
import GameChart from "./GameChart";
import GameBoard from "./GameBoard";
import GameHeader from "./GameHeader";

const TradePage = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();

  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";
  const time = params?.get("time") || "1m";
  const theme = params?.get("theme") || "dark";

  const [game_id, setGameId] = useState(games[0]?.code);
  const [isLight, setIsLight] = useState(false);
  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  useEffect(() => {
    if (!theme) return;
    setIsLight(theme === "light");
  }, [theme]);

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  return (
    <div
      className={classNames("flex flex-col flex-wrap flex-1 trade-page", {
        light: isLight,
      })}
    >
      <GameHeader
        data_price={priceBtc}
        game_id={game_id}
        isLight={isLight}
        setIsLight={() => setIsLight(!isLight)}
        time={time}
      />
      <div className="flex flex-1">
        <div className="relative w-full h-full">
          <div className="relative w-full h-full">
            <div className="w-full h-full">
              <GameChart game_id={game_id} isLight={isLight} />
            </div>
          </div>
        </div>
      </div>
      <GameBoard game_id={game_id} />
      {!user?.is_verify && <ModalPassword open />}
    </div>
  );
});

export default TradePage;
