/* eslint-disable react/no-danger */
import moment from "moment";
import React, { useState } from "react";
import i18next from "i18next";
import MenuChat from "../MenuChat";
import ModalImage from "./ModalImage";

function MessageRight({ item }) {
  const [openModal, setOpenModal] = useState();

  return (
    <li className="chat-list right" id="2">
      <div className="conversation-list">
        <div className="user-chat-content">
          <div className="ctext-wrap">
            <div className="ctext-wrap-content" id="2">
              {item?.file_path && (
                <img
                  role="presentation"
                  onClick={() => setOpenModal(true)}
                  src={item?.file_path}
                  alt=""
                />
              )}
              <p className="mb-0 ctext-content">
                <span
                  dangerouslySetInnerHTML={{
                    __html: item?.message?.split("\n").join("</br>"),
                  }}
                />
                {item?.status === "edit" && (
                  <span className="edit-chat">{i18next.t("fixed")}</span>
                )}
              </p>
            </div>
            <MenuChat item={item} />
          </div>
        </div>
      </div>
      {openModal && (
        <ModalImage
          image={item?.file_path}
          onClose={() => setOpenModal(false)}
        />
      )}
    </li>
  );
}

export default MessageRight;
