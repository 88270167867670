import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import i18next from "i18next";
import HistoryData from "./HistoryData";

const TaskPage = observer(() => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      <HeaderTop title={i18next.t("task_list")} isBack={false} />
      <div className="home-page !bg-[#13171a] pt-[46px]">
        <HistoryData />
      </div>
    </>
  );
});

export default TaskPage;
