import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Portal } from "react-portal";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";

function ModalPassword({ open, data, onClose }) {
  const navigate = useNavigate();
  const {
    authStore: { user, setIsVerify },
  } = useStores();

  const [pass, setPass] = useState();

  const handleSubmit = () => {
    if (!user?.wallet_password || user?.wallet_password !== pass) {
      setPass("");
      return NotificationManager.error(
        i18next.t("wallet_password_not_match_msg")
      );
    }
    return setIsVerify(true);
  };

  const handleChange = (event) => {
    setPass(event?.target?.value);
  };

  return (
    <Portal>
      <div
        className="modal modal-bet-game modal-gift"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="classic-bet-now">
            <div className="title">
              <h5>{i18next.t("verify")}</h5>
            </div>
            <div className="content">
              <div className="flex flex-col w-full">
                <div>
                  <div className="sbui-formlayout sbui-formlayout--large sbui-formlayout--responsive">
                    <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                      <div className="sbui-formlayout__label">
                        {i18next.t("wallet_password")}
                      </div>
                    </div>
                    <div className="sbui-formlayout__content-container-horizontal">
                      <div className="sbui-input-container">
                        <input
                          value={pass}
                          name="wallet_password"
                          placeholder={i18next.t("wallet_password")}
                          type="password"
                          className="sbui-input sbui-input--large"
                          defaultValue=""
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit">
              <button
                type="button"
                className="btn btn-close mr-6"
                style={{ width: 200 }}
                onClick={() => {
                  navigate("/");
                }}
              >
                {i18next.t("cancel")}
              </button>
              <button
                type="button"
                className="btn btn-submit"
                style={{ width: 200 }}
                onClick={handleSubmit}
              >
                {i18next.t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalPassword;
